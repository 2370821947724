
body {
  background: black;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .c-6.lefts {
    width: 20%;
    float: left;
    color: white;
    margin-right: 3%;
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    bottom:10px;
  
  }
  div#truck {
    height: 100vh;
    transform: translate(0);
}
  .example-thumb.example-thumb-0 {
    cursor: grab;
}
.example-thumb.example-thumb-0:active {
  cursor: grabbing;
}
  
  img.image2{
    width: 100% !important;
  }
  
  
  .fileContainer {
    background: transparent !important;
    color: white;
    border: none;
    width: 100%;
  }
  
  input {
    padding: 10px;
    font-size: 10px;
    text-align: center;
    font-size: 16px;
    width: 90%;
  }
  
  .fileContainer .chooseFileButton {
    background: #f52b80 !important;
    color: black !important;
  }
  
  .example-thumb.example-thumb-0::before {  font-size: 8px; content: 'IMG SIZE: ';}
  
  .example-thumb.example-thumb-0 {
    background: #8a8383;
    padding: 4px;
    width: 61px;
    text-align: center;
    margin-bottom: 30px!important;
    border: 1px solid;
    font-size: 11PX;
    margin-top: -9px;
  }
  .slider {
    margin-bottom: 15px;
    font-size: 14px;
  }
  img.image1 {
    height: auto !important;
    left: 0;
  }
  
  img.image2 {
    pointer-events: none;
    width: 110% !important;
      left: -5% !important;
  }
  
  img.uploadIcon {
    display: none;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  
    text-align:center;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
  
    text-align:center;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
  
    text-align:center;
  }
  
  
  .slider {
    background: #a09ca0;
    height: 6px;
    box-shadow: 1px 1px 13px 3px hsla(0, 0%, 0%, 0.6);
  }
  
  .fileContainer {
    margin: 0 !important;
  
  }
  
  .tds-text-input {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    z-index: 1;
    width: 140px;
    text-align: center;
  }
  
  small {
    background: black;
    color: white;
  }
  .sketch-picker {
    margin: auto;
}
img.truckBody {
  pointer-events: none;
}
.sketch-picker {
    position: fixed;
    bottom: 0;
    left: 0;
}
.chrome-picker {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
img.kidsuperlogo {
  position: absolute;
  z-index: 4;
  width: 250px;
  top:30px;
  left: 0;
  right: 0;
  margin: auto;
  
}
button {
  padding: 9px;
  margin: 7px;
  background: black;
  border: 2px solid;
  background-color: #999;
  background-image: linear-gradient(hsla(0, 76%, 65%, 0.53), hsla(330, 100%, 50%, 0.98));
  border: none;
  border-radius: .5em;
  box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.2), inset 0 2px 0 hsla(0,0%,100%,.1), inset 0 1.2em 0 hsla(0,0%,100%,0.1), inset 0 -0.2em 0 hsla(0,0%,100%,.1), inset 0 -0.25em 0 hsla(0,0%,0%,.25), 0 0.25em 0.25em hsla(0,0%,0%,.05);
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: bold;
  margin: 0 .5em 1em;
  padding: .5em 1.5em .75em;
  position: relative;
  text-decoration: none;
  text-shadow: 0 1px 1px hsla(0,0%,100%,.25);
  vertical-align: middle;
}
.fileContainer p {
  font-size: 12px;
  margin: 8px 0 4px;
  display: none;
}
button.capture {
  width: 200px;
  padding: 10px;
  font-size: 17px;
}
button.capture img {margin: -2px 7px;width: 19px;}
button:hover {
  outline: none;
}
button:hover,
button:focus {
  box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.2),
              inset 0 2px 0 hsla(0,0%,100%,.1),
              inset 0 1.2em 0 hsla(0,0%,100%,.1),
              inset 0 -.2em 0 hsla(0,0%,100%,.1),
              inset 0 -.25em 0 hsla(0,0%,0%,.25),
              inset 0 0 0 3em hsla(0,0%,100%,.2),
              0 .25em .25em hsla(0,0%,0%,.05);
}
button:active {
  box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.2),
              inset 0 2px 0 hsla(0,0%,100%,.1),
              inset 0 1.2em 0 hsla(0,0%,100%,.1),
              inset 0 0 0 3em hsla(0,0%,100%,.2),
              inset 0 .25em .5em hsla(0,0%,0%,.05),
              0 -1px 1px hsla(0,0%,0%,.1),
              0 1px 1px hsla(0,0%,100%,.25);
  margin-top: .25em;
  outline: none;
  padding-bottom: .5em;
}
span.captureInfo {
  color: #fff;
  position: absolute;
  left: 0;
  font-size: 10px;
  text-align: center;
  right: 0;
  bottom: 16px;
  background: black;
  width: 178px;
  margin: auto;
  padding: 7px;
}
.captureDiv img {
  border: 2px solid #ed3d80;
  box-shadow: 0px 7px 18px 5px #de4b4b82;
  width: 100%;
}
.captureDiv {
  position: fixed;
  right: 10px;
  width: 25%;
  margin: 20px;
  color: black;
  text-align: center;
  float: left;
  padding: 0px;
  transform: translate();
}
