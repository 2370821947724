@media only screen and (max-width:50em) {
    body {

    overflow: hidden;
    }

    .c-6.lefts {
        width: 90%;
        float: left;
        color: white;
        margin-right: 3%;
        z-index: 100;
        position: fixed;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
        bottom: 30px;
    }

    img.kidsuperlogo {
        position: absolute;
        z-index: 4;
        width: 120px;
        top: 14px;
        left: 0;
        right: 0;
        margin: auto;
    }


    .captureDiv {
        position: fixed;
        right: 10px;
        width: 55%;
        margin: 20px;
        color: black;
        text-align: center;
        float: left;
        padding: 0px;
        left: 0;
        top: 30vh;
        margin: auto;
    }

    span.captureInfo {
        color: #fff;
        position: absolute;
        left: 0;
        font-size: 7px;
        text-align: center;
        right: 0;
        bottom: 9px;
        background: black;
        width: 76%;
        margin: auto;
        padding: 4px;
    }

    .parent {
        width: 100% !important;
        float: left;
        height: 32vh !important;
        bottom: 0;
    }

}